export const state = () => ({
  isUpdate: false, // 자산수집 중 여부 true/false
});

export const mutations = {
  // 자산수집 중 여부 변경
  setIsUpdate(state, payload) {
    state.isUpdate = payload;
  },
};

export const getters = {
  // 자산수집 중 여부 확인
  getIsUpdate(state) {
    return state.isUpdate;
  },
};
